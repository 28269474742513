<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { Slide } from '@/helpers/Slides'
// import fillImage from '@/assets/slide-type-photowall-active.svg'
// import fitImage from '@/assets/slide-type-photowall-active.svg'
// import originalImage from '@/assets/slide-type-photowall-active.svg'
// import stretchImage from '@/assets/slide-type-photowall-active.svg'

import fillImage from '@/assets/fill.svg'
import fitImage from '@/assets/fit.svg'
import originalImage from '@/assets/original.svg'
import stretchImage from '@/assets/stretch.svg'
import CardSelectList from '@/components/CardSelectList.vue'
import { useI18n } from 'vue-i18n'
const props = defineProps({
  slideObj: Slide,
  visible: Boolean
})

const emit = defineEmits(['update', 'update:visible'])

const { t } = useI18n()
let slide
const objectFit = ref('ORIGINAL')
const toAll = ref(false)
const opened = computed(()=>props.visible)

const handleOk = () => {
  slide?.setObjectFit(objectFit.value)
  emit('update:visible', false)
  emit('update', slide.getDto())
  resetModal()
}

const objectFitOptions = reactive([
  {
    text: t('components.slideObjectFitEditModal.objectFitMap.ORIGINAL'),
    view: 'ORIGINAL',
    image: originalImage,
  },
  {
    text: t('components.slideObjectFitEditModal.objectFitMap.FIT'),
    view: 'FIT',
    image: fitImage,
  },
  {
    text: t('components.slideObjectFitEditModal.objectFitMap.FILL'),
    view: 'FILL',
    image: fillImage,
  },
  {
    text: t('components.slideObjectFitEditModal.objectFitMap.STRETCH'),
    view: 'STRETCH',
    image: stretchImage
  }
])


const handleCancel = () => {
  emit('update:visible', false)
  resetModal()
}

const resetModal = () => {
  slide = null
}

watch(() => props.visible, (visible) => {
  if (!visible) return
  slide = props.slideObj
  objectFit.value = slide.slideData.objectFit
})

</script>

<template>
  <a-modal
    v-model:open="opened"
    :title="$t('components.slideObjectFitEditModal.title')"
    destroy-on-close
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div style="margin-top: 32px;">
      <CardSelectList
        v-model:view="objectFit"
        :data="objectFitOptions"
      />
      <!--      <a-checkbox v-model:checked="toAll">-->
      <!--        {{ $t('components.slideObjectFitEditModal.toAll') }}-->
      <!--      </a-checkbox>-->
    </div>
  </a-modal>
</template>

<style scoped lang="less">

</style>
