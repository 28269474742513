<script setup>
import { WindowsFilled } from '@ant-design/icons-vue'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import { error } from '@/utils'
import dayTypeImage from '@/assets/calendar-day.svg'
import weekTypeImage from '@/assets/calenat-week.svg'
import monthTypeImage from '@/assets/calendar-month.svg'
import AddSocialAccountButton from '@/components/AddSocialAccountButton'
import { useI18n } from 'vue-i18n'
import { MicrosoftCalendarSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'
import CardSelectList from '@/components/CardSelectList.vue'

const props = defineProps({
  title: String,
  slideObj: MicrosoftCalendarSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, MicrosoftCalendarSlide, {
  disableOnNewSlide: true
})

const store = useStore()
const { t } = useI18n()
const calendarsLoading = ref(false)
const {
  view,
  socialMicrosoftAccountId,
  calendarIds
} = slide.value.slideData
const availableCalendars = ref([])
const state = reactive({
  view,
  socialMicrosoftAccountId,
  calendarIds
})
const viewData = computed(() => [
  {
    text: t('components.slides.calendarMicrosoft.day'),
    view: 'DAY',
    image: dayTypeImage
  },
  {
    text: t('components.slides.calendarMicrosoft.week'),
    view: 'WEEK',
    image: weekTypeImage
  },
  {
    text: t('components.slides.calendarMicrosoft.month'),
    view: 'MONTH',
    image: monthTypeImage
  }
])

const availableCalendarsOptions = computed(() => {
  return availableCalendars.value.map(calendar => {
    return {
      label: calendar.name,
      value: calendar.id
    }
  })
})

const microsoftAccounts = computed(() => store.getters['social/socialMicrosoftAccounts']
  .map(toStandard)
  .filter(({ scopes }) => scopes?.calendar)
)

const isValid = computed(() => {
  return (state.calendarIds?.length > 0)
})

watch(() => state.view, (value) => {
  updateSlideData({
    view: value
  })
  updateSlide()
})

watch(() => state.calendarIds, (value) => {
  updateSlideData({
    calendarIds: value
  })
  updateSlide()
})

watch(() => microsoftAccounts.value, (value, oldValue) => {
  if (value?.length - oldValue?.length === 1) {
    const newMicrosoftId = value?.find(({ id }) => !oldValue.some(({ id: oldId }) => oldId === id))?.id
    if (newMicrosoftId) {
      state.socialMicrosoftAccountId = newMicrosoftId
    }
  }
})

watch(() => state.socialMicrosoftAccountId, (curr, prev) => {
  if (curr && prev) {
    state.calendarIds = []
  }
  updateSlideData({
    socialMicrosoftAccountId: value
  })
  updateSlide()
})


watchEffect(() => {
  if (microsoftAccounts.value?.length === 1 ?? !state.socialMicrosoftAccountId) {
    state.socialMicrosoftAccountId = microsoftAccounts.value[0].id
  }
})

watchEffect(() => {
  if (state.socialMicrosoftAccountId) {
    calendarsLoading.value = true
    store.dispatch('social/getAvailableMicrosoftCalendarBySocialAccountId', state.socialMicrosoftAccountId).then(calendars => {
      availableCalendars.value = calendars
    }).catch(e => {
      error(e.message)
    }).then(() => {
      calendarsLoading.value = false
    })
  }
})

watchEffect(()=>{
  toggleDisabled(!isValid.value)
})

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.calendarMicrosoft.slideStep1.subTitle')"
    :title="$t('components.slides.calendarMicrosoft.slideStep1.title')"
  >
    <template #extra>
      <AddSocialAccountButton :socials="['microsoft']" />
    </template>
    <a-select
      v-model:value="state.socialMicrosoftAccountId"
      :placeholder="$t('components.slides.calendarMicrosoft.slideStep1.socialAccountSelectPlaceholder')"
      style="width: 100%"
    >
      <a-select-opt-group v-if="microsoftAccounts.length > 0">
        <template #label>
          <span>
            <WindowsFilled />
            Microsoft
          </span>
        </template>
        <a-select-option
          v-for="account in microsoftAccounts"
          :key="account.id"
          :value="account.id"
        >
          <div>
            @ {{ account.name }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.calendarMicrosoft.slideStep2.subTitle')"
    :title="$t('components.slides.calendarMicrosoft.slideStep2.title')"
  >
    <a-spin :spinning="calendarsLoading">
      <a-checkbox-group
        v-model:value="state.calendarIds"
        :options="availableCalendarsOptions"
        class="multirow-checkbox-group"
      />
    </a-spin>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.calendarMicrosoft.slideStep3.title')"
    no-border
  >
    <CardSelectList
      v-model:view="state.view"
      :data="viewData"
    />
    <div>
      <a-typography-text type="secondary">
        {{ $t('components.slides.calendarMicrosoft.availableOnly') }}
      </a-typography-text>
    </div>
  </SlideStep>
</template>

<style scoped>

</style>
