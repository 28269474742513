<template>
  <CEngineEditor
    v-if="false"
    :slide="activeSlide"
  />
  <CustomTemplateModal
    v-model:visible="showCustomTemplateModal"
    :slide-obj="activeSlide"
    :zone-key="activeSlideZoneKey"
    :zoning-layout-type="zoningLayoutType"
    @update="onSlideUpdate"
    @close="onSlidesUpdateCancel"
  />
  <AIContentSlideModal
    v-model:visible="showAIContentSlideModal"
    :slide-obj="activeSlide"
    @update="onSlideUpdate"
    @slide-duration-change="onSlideDurationChange"
    @close="onSlidesUpdateCancel"
  />
  <TemplateConstructorModal
    v-model:visible="showSmartTemplateModal"
    :slide-obj="isSmartTemplateActive ? activeSlide : null"
    @update="onSlideUpdate"
    @close="onSlidesUpdateCancel"
  />
  <SlideInfoEditModal
    v-model:visible="showEditSlideModal"
    :slide-obj="activeSlide"
    @update="onSlideUpdate"
  />
  <SlideObjectFitEditModal
    v-model:visible="showEditObjectFitModal"
    :slide-obj="activeSlide"
    @update="onSlideUpdate"
  />
  <PlaylistModal
    v-model:visible="showPlaylistModal"
    :playlist="playlist"
    @update="onPlaylistUpdate"
  />
  <SmartPlaylistInfoModal />
  <ExportSlideModal
    v-model:visible="showSlideExportModal"
    :playlist-id="playlistId"
    @select="onSlideExportPlaylistsSelect"
    @close="onSlideExportPlaylistsCancel"
  />
  <MoveToOtherGroupModal
    v-if="(!duplicatePlaylistOnMove && currentGroupPlaylistsMovable) || duplicatePlaylistOnMove"
    v-model:visible="showPlaylistMoveModal"
    :exclude-current-group="!duplicatePlaylistOnMove"
    do-not-exclude-branch
    :title="duplicatePlaylistOnMove ? $t('components.playlistPage.duplicatePlaylist') : $t('components.playlistPage.movePlaylist')"
    @select="onPlaylistMoveSelect"
    @close="onPlaylistMoveCancel"
  />
  <SlideModal
    v-model:visible="showSlideModal"
    :slide-type="activeSlideType"
    :slide="activeSlide"
    :is-main-zone="activeSlideZoneKey === 'MAIN'"
    @update="onSlideUpdate"
    @save-multiple="onMultipleSlidesSave"
    @slide-duration-change="onSlideDurationChange"
    @close="onSlidesUpdateCancel"
  />

  <PlaylistExpirationModal
    v-model:open="showExpirationSettingsModal"
    :options="autoDeleteOptions"
    @save="onPlaylistExpirationSettingsChange"
  />
  <a-drawer
    v-model:open="showChangeLog"
    class="custom-class"
    title="Playlist History"
    width="508"
    placement="right"
  >
    <ChangeLogsList />
  </a-drawer>
  <a-layout
    style="height: 100%;"
  >
    <a-layout-content
      id="playlist-page-content"
    >
      <a-spin
        :spinning="loading"
        class="full-height-spinner"
      >
        <template v-if="playlist">
          <a-page-header
            style="position: sticky; z-index: 3; top: 0; background-color: #fff"
          >
            <template #title>
              {{ playlist.name }}
              <a-typography-text
                v-if="autoDeleteOptions"
                type="warning"
              >
                {{ $t('components.playlistPage.playlistExpiresAt', {date: autoDeleteDate}) }}
              </a-typography-text>
            </template>
            <template
              #extra
            >
              <PlaylistPageExtra
                :show-playlist-move="!playlistIsMain && currentGroupPlaylistsMovable"
                :show-expiration-settings="!playlistIsMain"
                :show-change-log="playlistVersionsEnabled"
                :show-forced-playlist-option="showForcedPlaylistOption"
                :show-delete-playlist="(!currentGroupTypeIsSmart && !playlistIsMain) || (currentGroupTypeIsSmart && currentGroupPlaylistsMovable)"
                :disable-forced-playlist-option="currentGroupHasForcedPlaylist"
                :hide-rename="playlistIsMain"
                :sider-collapsed="siderCollapsed"
                @edit-playlist="onPlaylistEdit"
                @move-playlist="startPlaylistMove"
                @open-expiration-settings="openExpirationSettings"
                @duplicate-playlist="startPlaylistDuplicate"
                @delete-playlist="$emit('delete-playlist', playlistId)"
                @open-change-log="openChangeLog"
                @override-group-schedule="handleOverrideGroupSchedule"
                @toggle-sider="toggleSider"
              />
            </template>
            <div style="display: flex; gap: 8px;">
              <template v-if="!playlistIsMain">
                <PlaylistPlayPauseButton
                  :is-paused="playlist.paused"
                  :is-scheduled="!!playlist.isScheduled"
                  @toggle="handleTogglePlaylistPause"
                />
              </template>

              <a-tooltip
                placement="top"
                :title="$t('components.playlistPage.shuffleWidgetsTooltip')"
              >
                <a-button
                  :type="!zoningLayoutShuffle ? 'dashed' : 'default'"
                  @click="handleTogglePlaylistShuffle"
                >
                  <template #icon>
                    <NodeIndexOutlined />
                  </template>
                  {{ $t('components.playlistPage.shuffleWidgets') }}: {{ $t(`components.playlistPage.${zoningLayoutShuffle ? 'on': 'off'}`) }}
                </a-button>
              </a-tooltip>
              <SoundtrackButton />
            </div>
          </a-page-header>
          <a-layout-content
            ref="playlistWrapper"
            style="padding: 0 16px 16px; overflow-x: auto"
            class="playlist-wrapper"
            :class="{'dragging':isDraggingNewSlide}"
          >
            <a-collapse
              v-model:activeKey="openedLayoutZones"
              ghost
            >
              <ZoneCollapsePanel
                v-for="zone in layoutZonesSettings"
                :ref="(el) => setZoneRefs(zone.key, el)"
                :key="zone.key"
                :total-zones-number="layoutZonesSettings.length"
                :container-height="playlistWrapperHeight"
                :flex="layoutZonesSettings.length === 1"
                :zone-title="$t(`zoneNames.${zone.nameKey}`)"
                :active-zone="zone.zoneKey"
                :zoning-layout-settings="zoningLayoutSettings"
                :zone-key="zone.key"
                :zone-slides="zoneSlideListsFiltered[zone.key]"
                :zone-is-empty="zoneIsEmpty(zone.key)"
                :edit-only="editOnlyMode"
                :selected-slide-ids="selectedSlideIds"
                :widgets-display-restrictions="widgetDisplayRestrictions?.[zoningLayoutType]?.[zone.key]"
                @zone-slides-move="onSlideListChange"
                @slide-add="onSlideAdd"
                @slide-click="onSlideClick"
                @slide-edit="onSlideEdit"
                @slide-object-fit-edit="onSlideObjectFitEdit"
                @slide-delete="onSlideDelete"
                @slide-duration-change="onSlideDurationChange"
                @slide-paused-change="onSlidePausedChange"
                @slide-object-fit-change="onSlideObjectFitChange"
                @slide-duplicate="onSlideDuplicate"
                @slide-export="({slide})=>startSlideExport({slide, zoneKey: zone.key})"
                @slide-mute="onSlideToggleSound"
                @slide-start-drag="onStartDragNewSlide"
                @slide-stop-drag="onStopDragNewSlide"
              />
            </a-collapse>
          </a-layout-content>
          <StickyFooter>
            <PlaylistPageSelectionFooter
              v-if="selectedSlideIds.size"
              :selected-slides-number="selectedSlideIds.size"
              @delete="onSlidesBulkDelete"
              @duplicate="onSlidesBulkDuplicate"
              @export="startSlideExport"
              @deselect="deselectAll"
            />
            <FixedFooter>
              <div style="display: flex; gap: 8px;">
                <a-input
                  v-model:value="filterSlidesState.input"
                  allow-clear
                  :placeholder="$t('components.playlistPage.filterPlaceholder')"
                  style="width: 100%"
                  @change="debounceFilter"
                />
                <a-segmented
                  v-model:value="filterSlidesState.type"
                  :options="filterSlidesOptions"
                />
              </div>
            </FixedFooter>
          </StickyFooter>
        </template>
      </a-spin>
    </a-layout-content>
    <a-layout-sider
      style="background-color: #fff"
      :collapsed="siderCollapsed"
      collapsed-width="0"
      class="playlist-sider"
      width="250px"
    >
      <header>
        <a-segmented
          v-model:value="playlistSiderTab"
          block
          :options="playlistSiderTabOptions"
        />
      </header>
      <a-spin
        :spinning="loading"
      >
        <PlaylistWidgetsTab
          v-if="playlistSiderTab==='widgets'"
          v-model:filter-slide-types="filterSlideTypes"
          :sider-collapsed="siderCollapsed"
          :slide-type-list="slideTypeList"
          :disabled-widgets="disabledWidgets"
          :layout-zones-settings="layoutZonesSettings"
          :edit-only-mode="editOnlyMode"
          @start-drag-new-slide="onStartDragNewSlide"
          @stop-drag-new-slide="onStopDragNewSlide"
          @slide-add-last="onSlideAddLast"
        />
        <PlaylistDesignTab
          v-if="playlistSiderTab === 'design'"
          :transition-type="transitionType"
          :zoning-layout-settings="zoningLayoutSettings"
          @change-layout="handleZoningChange"
          @change-transition="onTransitionChange"
        />
      </a-spin>
    </a-layout-sider>
  </a-layout>
</template>

<script>
import {
  computed,
  createVNode,
  defineComponent,
  inject, nextTick, onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRaw,
  toRefs,
  watch,
  watchEffect
} from 'vue'
import { cloneDeep, debounce } from 'lodash-es'
import {
  ExclamationCircleOutlined,
  NodeIndexOutlined
} from '@ant-design/icons-vue'
import { error, formatDate, success } from '@/utils'
import FixedFooter from '@/components/FixedFooter'
import ExportSlideModal from '@/components/inputModals/ExportSlideModal.vue'
import PlaylistModal from '@/components/inputModals/PlaylistModal.vue'
import MoveToOtherGroupModal from '@/components/inputModals/MoveToOtherGroupModal.vue'
import { useRouter } from 'vue-router'
import SlideModal from '@/components/slideModal/SlideModal.vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { DEFAULT_TRANSITION, LAYOUT_ZONES_SETTINGS, ZONING_LAYOUTS } from '@/constants'
import ZoneCollapsePanel from '@/components/ZoneCollapsePanel'
import ChangeLogsList from '@/components/ChangeLogsList'
import TemplateConstructorModal from '@/components/slideModal/TemplateConstructorModal'
import AIContentSlideModal from '@/components/slideModal/AIContentSlideModal'
import { useI18n } from 'vue-i18n'
import { createSlides, DISABLED_WIDGETS_TYPES, WIDGET_TYPE_LIST_MENU_ITEMS } from '@/helpers/Slides'
import CustomTemplateModal from '@/components/slideModal/CustomTemplateModal'
import CEngineEditor from '@/components/cEngineEditor/CEngineEditor.vue'
import PlaylistDesignTab from '@/components/playlistPage/PlaylistDesignTab.vue'
import PlaylistWidgetsTab from '@/components/playlistPage/PlaylistWidgetsTab.vue'
import PlaylistPageExtra from '@/components/playlistPage/PlaylistPageExtra.vue'
import PlaylistPageSelectionFooter from '@/components/playlistPage/PlaylistPageSelectionFooter'
import SoundtrackButton from '@/components/playlistPage/SoundtrackButton.vue'
import SmartPlaylistInfoModal from '@/components/inputModals/SmartPlaylistInfoModal'
import StickyFooter from '@/components/StickyFooter'
import SlideInfoEditModal from '@/components/inputModals/SlideInfoEditModal'
import PlaylistPlayPauseButton from '@/components/playlistPage/PlaylistPlayPauseButton'
import PlaylistExpirationModal from '@/components/inputModals/PlaylistExpirationModal.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SlideObjectFitEditModal from '@/components/inputModals/SlideObjectFitEditModal.vue'

dayjs.extend(utc)

const FILTER = 'filter'
const HIGHLIGHT = 'highlight'
const DISABLED_WIDGETS_SLIDE_NAMES = DISABLED_WIDGETS_TYPES

const getSlideDtoWithoutWrapper = slide => slide.getDtoWithoutWrapper()

const getZoningLayoutSettingsByType = type => ZONING_LAYOUTS.find(layout => layout.type === type)

export default defineComponent({
  name: 'PlaylistPage',
  components: {
    SlideObjectFitEditModal,
    PlaylistExpirationModal,
    PlaylistPlayPauseButton,
    SlideInfoEditModal,
    StickyFooter,
    SmartPlaylistInfoModal,
    SoundtrackButton,
    PlaylistPageSelectionFooter,
    PlaylistPageExtra,
    PlaylistWidgetsTab,
    PlaylistDesignTab,
    CEngineEditor,
    CustomTemplateModal,
    AIContentSlideModal,
    TemplateConstructorModal,
    ChangeLogsList,
    ZoneCollapsePanel,
    SlideModal,
    MoveToOtherGroupModal,
    PlaylistModal,
    ExportSlideModal,
    FixedFooter,
    NodeIndexOutlined
  },
  inheritAttrs: false,
  props: {
    playlistId: {
      type: String,
      required: true
    },
  },
  emits: ['delete-playlist'],
  setup (props) {
    const store = useStore()
    const { t } = useI18n()
    const router = useRouter()
    const { playlistId } = toRefs(props)
    const openedLayoutZones = ref(['MAIN'])
    const isDraggingNewSlide = ref(false)
    const showPlaylistModal = ref(false)
    const showSlideModal = ref(false)
    const showPlaylistMoveModal = ref(false)
    const showSlideExportModal = ref(false)
    const showSmartTemplateModal = ref(false)
    const showCustomTemplateModal = ref(false)
    const showAIContentSlideModal = ref(false)
    const showEditSlideModal = ref(false)
    const showEditObjectFitModal = ref(false)
    const showChangeLog = ref(false)
    const slideIdToExport = ref(null)
    const siderCollapsed = ref(JSON.parse(window.localStorage.getItem('playlistSiderCollapsed') || 'false'))
    const filterSlideTypes = ref('')
    const activeSlideType = ref(null)
    const activeSlideZoneKey = ref(null)
    const activeSlide = ref(null)
    const playlistWrapper = ref(null)
    const duplicatePlaylistOnMove = ref(false)
    const showExpirationSettingsModal = ref(false)
    const selectedSlideIds = ref(new Set())
    const selectedZoneKey = ref(null)
    const addSlideAfterIndex = ref(null)
    const playlistSiderTab = ref('widgets')
    const onboardingService = inject('onboardingService')
    const playlist = computed(() => store.getters['playlist/playlist'])
    const groupId = computed(() => store.getters['groups/currentGroupId'])
    const playlistVersionsEnabled = computed(() => store.getters['workspace/features/playlistVersionsEnabled'])
    const playlistIsMain = computed(() => store.getters['playlist/playlistIsMain'])
    const playlistTransitionType = computed(() => store.getters['playlist/playlistTransitionType'])
    const transitionType = ref(playlistTransitionType.value || DEFAULT_TRANSITION)
    const widgetDisplayRestrictions = computed(() => store.getters['playlist/widgetDisplayRestrictions'])
    const playlistLoading = computed(() => store.getters['playlist/playlistLoading'])
    const groupLoading = computed(() => store.getters['groups/currentGroupIsLoading'])
    const currentGroupTypeIsSmart = computed(() => store.getters['groups/currentGroupTypeIsSmart'])
    const currentGroupPlaylistsMovable = computed(() => store.getters['groups/currentGroupPlaylistsMovable'] && !playlistIsMain.value)
    const zoningLayoutType = computed(() => store.getters['playlist/zoningLayoutType'])
    const zoningLayoutShuffle = computed(() => store.getters['playlist/zoningLayoutShuffle'])
    const mainZoneSlides = computed(() => store.getters['playlist/layoutMainZoneSlides'])
    const zoneASlides = computed(() => store.getters['playlist/layoutZoneASlides'])
    const zoneBSlides = computed(() => store.getters['playlist/layoutZoneBSlides'])
    const currentGroupScheduleId = computed(() => store.getters['groups/currentGroupScheduleId'])
    const currentGroupHasForcedPlaylist = computed(() => store.getters['groups/currentGroupHasForcedPlaylist'])
    const autoDeleteOptions = computed(() => playlist.value?.autoDeleteOptions)
    const autoDeleteDate = computed(() => playlist.value?.autoDeleteOptions?.deleteAt ? dayjs(playlist.value.autoDeleteOptions.deleteAt).utc().format('MMM DD YYYY') : null)
    const playlistWrapperHeight = ref(0)
    const zoneRefs = reactive({
      MAIN: null,
      ZONE_A: null,
      ZONE_B: null
    })
    const zoningLayoutSettings = computed(() => {
      return zoningLayoutType.value ? getZoningLayoutSettingsByType(zoningLayoutType.value) : null
    })
    const slideTypeList = computed(() => {
      return WIDGET_TYPE_LIST_MENU_ITEMS.filter(w => {
        return w.id.toLowerCase().includes(filterSlideTypes.value.toLowerCase()) ||
         w.name.toLowerCase().includes(filterSlideTypes.value.toLowerCase())
      })
    })
    const loading = computed(() => playlistLoading.value || groupLoading.value)
    const editOnlyMode = computed(() => filterSlidesState.type === FILTER && !!filterSlidesState.input)
    const highlightMode = computed(() => filterSlidesState.type === HIGHLIGHT && !!filterSlidesState.input)
    const layoutZonesSettings = computed(() => LAYOUT_ZONES_SETTINGS.slice(0, zoningLayoutSettings.value?.zones))
    const showForcedPlaylistOption = computed(() => !currentGroupTypeIsSmart.value)
    const playlistSiderTabOptions = computed(() => [
      { label: t('components.playlistPage.widgets'), value: 'widgets' },
      { label: t('components.playlistPage.design'), value: 'design' }
    ])
    const isSmartTemplateActive = computed(()=> activeSlideType.value === 'SmartTemplateSlide')

    const zoneSlidesRaw = reactive({
      MAIN: mainZoneSlides,
      ZONE_A: zoneASlides,
      ZONE_B: zoneBSlides
    })

    const filterSlidesOptions = computed(() => [
      { label: t('components.playlistPage.filter'), value: FILTER },
      { label: t('components.playlistPage.highlight'), value: HIGHLIGHT }
    ])

    const zoneSlideLists = reactive({
      MAIN: [],
      ZONE_A: [],
      ZONE_B: []
    })

    const zoneSlideListsFiltered = reactive({
      MAIN: [],
      ZONE_A: [],
      ZONE_B: []
    })

    const filterSlidesState = reactive({
      input: '',
      type: FILTER
    })

    let resizeObserver

    const setupResizeObserver = () => {
      if (!resizeObserver && playlistWrapper.value) {
        resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            playlistWrapperHeight.value = entry.contentRect.height
          }
        })

        resizeObserver.observe(playlistWrapper.value.$el)
      }
    }

    onMounted(() => {
      setSlideLists()
      setSlideListsFiltered()
      setupResizeObserver()
    })

    onBeforeUnmount(() => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    })

    const mapRawSlidesToSlideList = (raw, currentSlideObjects) => {
      if (!currentSlideObjects || !currentSlideObjects.length) {
        return createSlides(cloneDeep(raw))
      }
      return cloneDeep(raw).map(slide => {
        const existing = currentSlideObjects.find((slideObj) => slideObj.id === slide.id)
        if (existing) {
          return existing.updateSlide(slide)
        } else {
          return (createSlides([slide]))?.[0]
        }
      })
    }

    const setSlideLists = () => {
      zoneSlideLists.MAIN = mapRawSlidesToSlideList(zoneSlidesRaw.MAIN, zoneSlideLists.MAIN)
      zoneSlideLists.ZONE_A = mapRawSlidesToSlideList(zoneSlidesRaw.ZONE_A, zoneSlideLists.ZONE_A)
      zoneSlideLists.ZONE_B = mapRawSlidesToSlideList(zoneSlidesRaw.ZONE_B, zoneSlideLists.ZONE_B)
    }

    const setSlideListsFiltered = () => {
      zoneSlideListsFiltered.MAIN = getFilteredSlideList(zoneSlideLists.MAIN)
      zoneSlideListsFiltered.ZONE_A = getFilteredSlideList(zoneSlideLists.ZONE_A)
      zoneSlideListsFiltered.ZONE_B = getFilteredSlideList(zoneSlideLists.ZONE_B)
    }

    const debounceFilter = debounce(setSlideListsFiltered, 300)

    const getFilteredSlideList = (slideList) => {
      if (editOnlyMode.value) {
        return slideList.filter(slideIncludesFilters)
            .map(slide => {
              slide.setEditOnlyMode(true)
              slide.setHighlighted(false)
              return slide
            })
      } else if (highlightMode.value) {
        return slideList.map(slide => {
          slide.setHighlighted(slideIncludesFilters(slide))
          slide.setEditOnlyMode(false)
          return slide
        })
      } else {
        return slideList.map(slide => {
          slide.setHighlighted(false)
          slide.setEditOnlyMode(false)
          return slide
        })
      }
    }

    const slideIncludesFilters = (slide) => {
      const filter = filterSlidesState.input?.toLowerCase()
      return slide.widgetType.toLowerCase().includes(filter) ||
          slide.getName()?.toLowerCase().includes(filter)
    }

    const handleAddSlide = ({
      slideType: type,
      zoneKey
    }) => {
      activeSlideType.value = type
      activeSlideZoneKey.value = zoneKey || 'MAIN'
      if (type === 'CustomSlide') {
        openCustomTemplateEditor()
        return
      }
      if (type === 'SmartTemplateSlide') {
        openSmartTemplateEditor()
        return
      }
      if (type === 'AISlide') {
        openAiContentSlideModal()
        return
      }
      showSlideModal.value = true
    }

    const resetSelection = () => {
      activeSlide.value = null
    }

    const handleCreateSlide = async({
      payload,
      zoneKey,
      cb
    }) => {
      return store.dispatch('playlist/createPlaylistSlide', {
        payload,
        zoneKey
      }).then(() => {
        openedLayoutZones.value = openedLayoutZones.value.length ? openedLayoutZones.value : ['MAIN']
        resetSelection()
        cb && cb()
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const handleUpdateSlide = async({
      payload,
      zoneKey,
      changeLogs
    }) => {
      return store.dispatch('playlist/updatePlaylistSlide', {
        payload,
        zoneKey,
        changeLogs
      }).then(() => {
        resetSelection()
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const onSlideClick = ({
      slide,
      zoneKey,
      e
    }) => {
      if (e.shiftKey) {
        if (selectedZoneKey.value && selectedZoneKey.value !== zoneKey) {
          return
        }
        if (!selectedSlideIds.value?.size) {
          selectedZoneKey.value = zoneKey
        }
        handleSelectSlide(slide.id)
      } else {
        handleEditSlide({
          slide,
          zoneKey
        })
      }
    }

    const onSlideEdit = ({ slide, zoneKey }) => {
      showEditSlideModal.value = true
      activeSlide.value = slide
      activeSlideZoneKey.value = zoneKey
    }

    const onSlideObjectFitEdit = ({ slide, zoneKey }) => {
      showEditObjectFitModal.value = true
      activeSlide.value = slide
      activeSlideZoneKey.value = zoneKey
    }

    const onSlidesBulkDelete = () => {
      const zones = []
      const zoneKey = selectedZoneKey.value || 'MAIN'

      const zoneKeys = [...Object.keys(zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)

      let slidesToDeleteArray

      zoneKeys.forEach((zone) => {
        const isCurrentZone = zone === zoneKey
        const slides = isCurrentZone
            ? zoneSlideLists[zone].filter(slide => !selectedSlideIds.value.has(slide.id))
            : zoneSlideLists[zone]

        zones.push({ slides: slides.map(getSlideDtoWithoutWrapper) })
        if (isCurrentZone) {
          slidesToDeleteArray = zoneSlideLists[zone].filter(slide => selectedSlideIds.value.has(slide.id))
        }
      })

      const input = {
        layout: {
          type: zoningLayoutType.value,
          zones
        }
      }

      const changeLogs = slidesToDeleteArray.map(slide => {
        return {
          slideDeleted: {
            slideId: slide.id,
            slideName: slide.getName()
          }
        }
      })

      handleUpdatePlaylist({
        input,
        changeLogs
      })
    }

    const onSlideDuplicate = ({
      slide,
      zoneKey
    }) => {
      slide?.id && onSlidesBulkDuplicate({
        slideIds: new Set([slide.id]),
        zoneKey
      })
    }

    const onSlidesBulkDuplicate = ({
      slideIds,
      zoneKey
    } = {}) => {
      const slideIdsToDuplicate = slideIds || selectedSlideIds.value
      zoneKey = zoneKey || selectedZoneKey.value || 'MAIN'
      const zones = []
      const zoneKeys = [...Object.keys(zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)
      let slidesToDuplicateArray

      zoneKeys.forEach((zone) => {
        const isCurrentZone = zone === zoneKey
        let slides = zoneSlideLists[zone]
        if (isCurrentZone) {
          slidesToDuplicateArray = slides.filter(slide => slideIdsToDuplicate.has(slide.id))
          slides = [...slides, ...slidesToDuplicateArray]
        }

        zones.push({ slides: slides.map(getSlideDtoWithoutWrapper) })
      })

      const input = {
        layout: {
          type: zoningLayoutType.value,
          zones: zones
        }
      }

      const changeLogs = slidesToDuplicateArray.map(slide => {
        return {
          slideDuplicated: {
            slideId: slide.id,
            slideName: slide.getName()
          }
        }
      })

      handleUpdatePlaylist({
        input,
        changeLogs
      })
    }

    const resetMultipleSelection = () => {
      selectedSlideIds.value.clear()
    }

    const handleUpdatePlaylist = ({
      input,
      changeLogs
    }) => {
      return store.dispatch('playlist/updatePlaylist', {
        input,
        changeLogs
      }).then(() => {
        success()
        resetMultipleSelection()
      }).catch((e) => {
        error(e.message)
      })
    }

    const handleSelectSlide = (id) => {
      selectedSlideIds.value.has(id) ? selectedSlideIds.value.delete(id) : selectedSlideIds.value.add(id)
      if (!selectedSlideIds.value?.size) {
        selectedZoneKey.value = null
      }
    }

    const handleEditSlide = ({
      slide,
      zoneKey
    }) => {
      const { id = null } = slide
      const type = slide.constructor.type
      activeSlideType.value = type
      activeSlideZoneKey.value = zoneKey
      activeSlide.value = slide
      if (type === 'CustomSlide') {
        openCustomTemplateEditor()
        return
      }
      if (type === 'SmartTemplateSlide') {
        return openSmartTemplateEditor()
      }
      if (type === 'AISlide') {
        return openAiContentSlideModal()
      }
      showSlideModal.value = true
    }

    const deselectAll = () => {
      selectedSlideIds.value.clear()
      selectedZoneKey.value = null
    }

    const openSmartTemplateEditor = () => {
      showSmartTemplateModal.value = true
    }

    const openCustomTemplateEditor = async () => {
      // showCustomTemplateModal.value = false
      // await nextTick()
      showCustomTemplateModal.value = true
    }

    const openAiContentSlideModal = () => {
      showAIContentSlideModal.value = true
    }

    const openExpirationSettings = () => {
      showExpirationSettingsModal.value = true
    }

    const onSlideDelete = ({
      slide,
      zoneKey
    }) => {
      const payload = {
        slideId: slide.id
      }

      const changeLogs = [{
        slideDeleted: {
          slideId: slide.id,
          slideName: slide.getName()
        }
      }]

      selectedSlideIds.value.delete(slide.id)

      return store.dispatch('playlist/deletePlaylistSlide', {
        payload,
        zoneKey,
        changeLogs
      }).then(() => {
        resetSelection()
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const onMultipleSlidesSave = async ({ widgetsModels, afterSlideId }) => {
      const zoneKey = activeSlideZoneKey.value
      const activeSlideValue = activeSlide.value
      for (let i = 0; i < widgetsModels.length; i++) {
        const dto = widgetsModels[i]
        await updateOrCreateSlide(dto, {afterSlideId, zoneKey, activeSlideValue})
      }
      addSlideAfterIndex.value = null
    }

    const updateOrCreateSlide = async (widgetModel, {afterSlideId, zoneKey, activeSlideValue} = {}) => {
      activeSlideValue = activeSlideValue || activeSlide.value
      const activeSlideId = activeSlideValue?.id
      const input = widgetModel
      zoneKey = zoneKey || activeSlideZoneKey.value
      const afterIndex = addSlideAfterIndex.value
      const isNewSlide = !input.widgetId
      delete input.widgetId
      const payload = {
        input
      }
      if (isNewSlide) {
        if (afterSlideId) {
          payload.afterSlideId = afterSlideId
        }
        else if (afterIndex) {
          const prevSlide = zoneSlideLists[zoneKey][afterIndex - 1] || null
          if (prevSlide) {
            payload.afterSlideId = prevSlide.id || null
          }
        }
        if (!openedLayoutZones.value?.includes(zoneKey)) {
          openedLayoutZones.value?.push(zoneKey)
        }
        const isLast = afterIndex === zoneSlideLists[zoneKey]?.length
        await handleCreateSlide({
          payload,
          zoneKey,
          cb: () => {
            if (isLast) {
              zoneRefs[zoneKey]?.scrollToBottom && zoneRefs[zoneKey]?.scrollToBottom()
            }
          }
        }).then(()=>{
          if (zoneKey === 'MAIN') {
            if (input.createWidgetWrapper?.widgetWorldClock) {
              onboardingService.proceedFrom('worldClock')
            }
            else if (input.createWidgetWrapper?.widgetWeather) {
              onboardingService.proceedFrom('weatherForecast')
            }
          }
        })
      } else {
        payload.slideId = activeSlideId
        activeSlideValue && activeSlideValue.setWaitingForUpdate(true)
        const changeLogs = [{
          slideUpdated: {
            slideId: activeSlideId,
            slideName: activeSlide.value?.getName()
          }
        }]
        await handleUpdateSlide({
          payload,
          zoneKey,
          changeLogs
        })
      }
    }

    const onSlideUpdate = (widgetModel) => {
      updateOrCreateSlide(widgetModel)
      resetSelection()
      addSlideAfterIndex.value = null
    }

    const onSlideToggleSound = ({
      slide,
      mute,
      zoneKey
    }) => {
      slide.setMute(mute)
      slide.waitingForUpdate = true
      const payload = {
        slideId: slide.id,
        input: slide.getDto({ ignoreWidgetId: true })
      }
      const changeLogs = [{
        ...(mute ? { slideMuted: { slideId: slide.id } } : {
          slideUnmuted: {
            slideId: slide.id,
            slideName: slide.getName()
          }
        })
      }]
      handleUpdateSlide({
        payload,
        zoneKey,
        changeLogs
      })
    }

    const onSlideObjectFitChange = ({
      slide,
      objectFit,
      zoneKey
    }) => {
      slide.setObjectFit(objectFit)
      slide.waitingForUpdate = true
      const payload = {
        slideId: slide.id,
        input: slide.getDto({ ignoreWidgetId: true })
      }
      const changeLogs = [{
        slideUpdated: {
          slideId: slide.id,
          slideName: slide.getName()
        }
      }]
      handleUpdateSlide({
        payload,
        zoneKey,
        changeLogs
      })
    }

    const onSlidesUpdateCancel = () => {
      if (activeSlideZoneKey.value === 'MAIN') {
        if (activeSlideType.value === 'ClockSlide') {
          onboardingService.proceedFrom('worldClock')
        }
        else if (activeSlideType.value === 'WeatherSlide') {
          onboardingService.proceedFrom('weatherForecast')
        }
      }
      resetSelection()
    }

    const onSlideAdd = ({
      afterIndex,
      slideType,
      zoneKey
    }) => {
      addSlideAfterIndex.value = afterIndex
      zoneSlideLists[zoneKey].splice(afterIndex, 1)
      handleAddSlide({
        slideType,
        zoneKey
      })
    }

    const onSlideAddLast = (slideType, optZoneKey) => {
      const [zoneKey] = optZoneKey
          ? [optZoneKey]
          : openedLayoutZones.value?.length === 1
              ? openedLayoutZones.value
              : ['MAIN']
      addSlideAfterIndex.value = zoneSlideLists[zoneKey]?.length || 0
      handleAddSlide({
        slideType,
        zoneKey
      })
    }

    const onSlideListChange = ({
      slideIndex,
      down,
      slide,
      zoneKey
    }) => {
      const fromZoneKey = store.getters['slides/draggingSlideFromZone']
      const fromZoneId = fromZoneKey ? store.getters['playlist/layoutZoneIdByKey'](fromZoneKey) : null
      const toZoneId = store.getters['playlist/layoutZoneIdByKey'](zoneKey)
      const sameZone = fromZoneKey === zoneKey
      const prevSlideIndex = sameZone ?
          down ? slideIndex : slideIndex - 1
          : slideIndex - 1
      const prevSlide = zoneSlideLists[zoneKey][prevSlideIndex] || null
      const payload = {
        slideId: slide.id,
        toZoneId
      }
      payload.afterSlideId = prevSlide?.id || null
      const changeLogs = [
        {
          slideChangedOrder: {
            slideId: slide.id,
            slideName: slide.getName()
          }
        }
      ]
      store.dispatch('playlist/movePlaylistSlide', {
        payload,
        zoneId: fromZoneId,
        changeLogs
      }).then(() => {
        success()
      })
    }

    const onSlideDurationChange = ({
      slide,
      duration,
      widgetId,
      toAll,
      zoneKey
    }) => {
      const {
        id,
        paused
      } = slide
      if (toAll) {
        const zones = []

        for (let zone in zoneSlideLists) {
          const isCurrentZone = zone === zoneKey
          zones.push({
                slides: zoneSlideLists[zone].map(slide => {
                  slide.waitingForUpdate = true
                  return {
                    ...slide.getDtoWithoutWrapper(),
                    ...(isCurrentZone ? { duration } : null),
                  }
                })
              }
          )
        }

        const input = {
          layout: {
            type: zoningLayoutType.value,
            zones: zones.slice(0, zoningLayoutSettings.value.zones)
          }
        }

        const changeLogs = zoneSlideLists[zoneKey].map(slide => {
          return {
            slideChangedDuration: {
              slideId: slide.id
            }
          }
        })

        handleUpdatePlaylist({
          input,
          changeLogs
        })
      } else {
        slide.waitingForUpdate = true
        const payload = {
          slideId: id,
          input: {
            duration,
            paused,
            widgetId
          }
        }
        const changeLogs = [{
          slideChangedDuration: {
            slideId: id,
            slideName: slide.getName()
          }
        }]
        handleUpdateSlide({
          payload,
          zoneKey,
          changeLogs
        })
      }
    }

    const onSlidePausedChange = ({
      slide,
      paused,
      widgetId,
      zoneKey
    }) => {
      const {
        id,
      } = slide

      slide.waitingForUpdate = true
      const payload = {
        slideId: id,
        input: {
          paused,
          widgetId
        }
      }
      const changeLogs = [{
        slideUpdated: {
          slideId: id,
          slideName: slide.getName()
        }
      }]
      handleUpdateSlide({
        payload,
        zoneKey,
        changeLogs
      })
    }

    const startSlideExport = ({
      slide,
      zoneKey
    } = {}) => {
      selectedZoneKey.value = zoneKey || selectedZoneKey.value
      showSlideExportModal.value = true
      slideIdToExport.value = slide?.id || null
    }

    const getSelectedWidgets = (keys) => {
      return zoneSlideLists[selectedZoneKey.value]?.filter(s => keys.has(s.id))
    }

    const onSlideExportPlaylistsSelect = (references) => {
      const keys = slideIdToExport.value ? new Set([slideIdToExport.value]) : toRaw(selectedSlideIds.value)
      const slideIds = getSelectedWidgets(keys).map(slide => slide.id)
      const fromPlaylistId = playlistId.value
      const zoneKey = selectedZoneKey.value
      const fromZoneId = store.getters['playlist/layoutZoneIdByKey'](zoneKey)
      const payload = {
        slideIds,
        fromPlaylistId,
        fromZoneId,
        toReferences: references
      }
      slideIdToExport.value = null
      store.dispatch('playlist/copyPlaylistSlides', payload).then(() => {
        resetMultipleSelection()
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const onSlideExportPlaylistsCancel = (e) => {
      slideIdToExport.value = null
    }

    const startPlaylistMove = () => {
      duplicatePlaylistOnMove.value = false
      showPlaylistMoveModal.value = true
    }

    const startPlaylistDuplicate = () => {
      duplicatePlaylistOnMove.value = true
      showPlaylistMoveModal.value = true
    }

    const onPlaylistMoveSelect = (groupId) => {
      if (duplicatePlaylistOnMove.value) {
        return handlePlaylistCopy({ groupId }).then(() => {
          success()
        }).catch((e) => {
          error(e.message || 'Error')
        })
      } else {
        handlePlaylistMove({ groupId }).then(() => {
          router.replace({ params: { groupId } })
          success()
        }).catch((e) => {
          error(e.message || 'Error')
        })
      }
    }

    const handlePlaylistCopy = (payload) => {
      return store.dispatch('playlist/copyPlaylist', payload)
    }

    const handlePlaylistMove = (payload) => {
      return store.dispatch('playlist/movePlaylist', payload)
    }

    const onPlaylistMoveCancel = () => {
    }

    const toggleSider = () => {
      siderCollapsed.value = !siderCollapsed.value
      window.localStorage.setItem('playlistSiderCollapsed', JSON.stringify(siderCollapsed.value))
    }

    const onStartDragNewSlide = () => {
      isDraggingNewSlide.value = true
    }

    const onStopDragNewSlide = () => {
      isDraggingNewSlide.value = false
    }

    const onPlaylistEdit = () => {
      showPlaylistModal.value = true
    }

    const onPlaylistUpdate = ({
      name,
      color
    }) => {
      const changeLogs = [{
        unknownChanges: {}
      }]
      handleUpdatePlaylist({
        input: {
          name,
          color
        },
        changeLogs
      })
    }

    const onPlaylistExpirationSettingsChange = (input) => {
      const changeLogs = [{
        unknownChanges: {}
      }]
      handleUpdatePlaylist({
        input,
        changeLogs
      })
    }

    const onTransitionChange = (value) => {

      const zoneKeys = [...Object.keys(zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)

      const zones = zoneKeys.map(zoneKey => {
        return { slides: zoneSlideLists[zoneKey].map(getSlideDtoWithoutWrapper) }
      })

      const input = {
        layout: {
          transitionType: value,
          type: zoningLayoutType.value,
          zones: zones
        }
      }

      const changeLogs = [{
        transitionTypeChanged: {}
      }]

      handleUpdatePlaylist({
        input,
        changeLogs
      })
    }

    const handleOverrideGroupSchedule = () => {
      return Modal.confirm({
        title: t('components.playlistPage.overrideModalTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        okText: t('components.playlistPage.overrideModalOkText'),
        cancelText: t('components.playlistPage.overrideModalCancelText'),
        content: t('components.playlistPage.overrideModalCancelContent'),
        onOk () {
          overrideGroupSchedule()
        }
      })
    }

    const overrideGroupSchedule = () => {
      const payload = {
        id: currentGroupScheduleId.value,
        input: {
          data: {
            forcedPlaylistId: playlistId.value
          }
        }
      }
      return store.dispatch('groups/updateCurrentGroupSchedule', payload).then(() => {
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const handleZoningChange = (layout) => {
      if (zoningLayoutSettings.value.zones <= layout.zones) {
        return changeZoning(layout)
      }
      if (layout.zones === 2 && zoneIsEmpty('ZONE_B')) {
        return changeZoning(layout)
      }
      if (layout.zones === 1 && zoneIsEmpty('ZONE_A') && zoneIsEmpty('ZONE_B')) {
        return changeZoning(layout)
      }
      return Modal.confirm({
        title: t('components.playlistPage.zoneModalTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        okText: t('components.playlistPage.zoneModalOkText'),
        cancelText: t('components.playlistPage.zoneModalCancelText'),
        content: t('components.playlistPage.zoneModalCancelContent'),
        onOk () {
          changeZoning(layout)
        }
      })
    }

    const changeZoning = ({
      type,
      zones: zonesCount
    }) => {
      const zoneKeys = [...Object.keys(zoneSlideLists)].slice(0, zonesCount)

      const zones = zoneKeys.map(zoneKey => {
        return { slides: zoneSlideLists[zoneKey].map(getSlideDtoWithoutWrapper) }
      })

      const input = {
        layout: {
          type,
          zones: zones
        }
      }

      const changeLogs = [{
        zoneSettingsUpdated: {}
      }]

      handleUpdatePlaylist({
        input,
        changeLogs
      }).then(() => {
        openedLayoutZones.value = [...zoneKeys]
      })
    }

    const openChangeLog = () => {
      showChangeLog.value = true
    }

    const handleTogglePlaylistPause = (value) => {
      const changeLogs = [{
        unknownChanges: {}
      }]
      handleUpdatePlaylist({
        input: { paused: value },
        changeLogs
      }).then(() => {
        playlist.value.paused = value
      })
    }

    const handleTogglePlaylistShuffle = () => {
      const zoneKeys = [...Object.keys(zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)

      const zones = zoneKeys.map(zoneKey => {
        return { slides: zoneSlideLists[zoneKey].map(getSlideDtoWithoutWrapper) }
      })

      const input = {
        layout: {
          type: zoningLayoutType.value,
          zones: zones,
          shuffle: !zoningLayoutShuffle.value
        }
      }

      const changeLogs = [{
        unknownChanges: {}
      }]

      handleUpdatePlaylist({
        input,
        changeLogs
      })
    }

    const zoneIsEmpty = (zoneKey) => zoneSlideLists[zoneKey].length === 0

    const setZoneRefs = (zoneKey, ref) => {
      zoneRefs[zoneKey] = ref
    }

    const setPreferredPlaylist = (playlistId) => {
      const preferredPlaylists = JSON.parse(window.localStorage.getItem('preferredGroupPlaylists')) || {}
      preferredPlaylists[groupId.value] = playlistId
      window.localStorage.setItem('preferredGroupPlaylists', JSON.stringify(preferredPlaylists))
    }

    watch(() => props.playlistId, () => {
      setPreferredPlaylist(playlistId.value)
      resetMultipleSelection()
      openedLayoutZones.value = ['MAIN']
      selectedZoneKey.value = null
      setSlideLists()
      setSlideListsFiltered()
    })

    watch(playlist, () => {
      setSlideLists()
      setSlideListsFiltered()
    })

    watch(playlistTransitionType, (value) => {
      transitionType.value = value || DEFAULT_TRANSITION
    })

    watch(() => filterSlidesState.type, () => {
      setSlideListsFiltered()
    })

    watchEffect(() => {
      store.dispatch('playlist/getPlaylist', playlistId.value)
    })

    watch(playlistWrapper, (newVal) => {
      if (newVal) {
        setupResizeObserver()
      }
    })

    return {
      playlist,
      playlistIsMain,
      openedLayoutZones,
      zoningLayoutSettings,
      autoDeleteDate,
      siderCollapsed,
      filterSlideTypes,
      filterSlidesState,
      editOnlyMode,
      loading,
      activeSlide,
      activeSlideType,
      slideTypeList,
      selectedSlideIds,
      isDraggingNewSlide,
      duplicatePlaylistOnMove,
      showSlideModal,
      showPlaylistModal,
      showSlideExportModal,
      showPlaylistMoveModal,
      currentGroupHasForcedPlaylist,
      debounceFilter,
      zoneSlideLists,
      zoneSlideListsFiltered,
      layoutZonesSettings,
      playlistSiderTab,
      transitionType,
      showChangeLog,
      zoningLayoutType,
      widgetDisplayRestrictions,
      showEditSlideModal,
      showEditObjectFitModal,
      showSmartTemplateModal,
      showCustomTemplateModal,
      showAIContentSlideModal,
      showExpirationSettingsModal,
      currentGroupPlaylistsMovable,
      currentGroupTypeIsSmart,
      showForcedPlaylistOption,
      playlistVersionsEnabled,
      isSmartTemplateActive,
      zoningLayoutShuffle,
      activeSlideZoneKey,
      disabledWidgets: DISABLED_WIDGETS_SLIDE_NAMES,
      playlistSiderTabOptions,
      filterSlidesOptions,
      playlistWrapper,
      playlistWrapperHeight,
      zoneRefs,
      autoDeleteOptions,
      setZoneRefs,
      zoneIsEmpty,
      handleZoningChange,
      deselectAll,
      toggleSider,
      onSlideAdd,
      onSlideAddLast,
      onStartDragNewSlide,
      onStopDragNewSlide,
      onPlaylistEdit,
      onSlideListChange,
      onMultipleSlidesSave,
      onSlideUpdate,
      onSlideToggleSound,
      onSlideObjectFitChange,
      onSlideDelete,
      onSlideClick,
      onSlideEdit,
      onSlideObjectFitEdit,
      onPlaylistMoveSelect,
      onPlaylistMoveCancel,
      onSlideDurationChange,
      onSlidePausedChange,
      onSlidesUpdateCancel,
      onSlidesBulkDelete,
      onSlideDuplicate,
      onSlidesBulkDuplicate,
      onSlideExportPlaylistsSelect,
      onSlideExportPlaylistsCancel,
      onPlaylistUpdate,
      onPlaylistExpirationSettingsChange,
      onTransitionChange,
      openChangeLog,
      openExpirationSettings,
      handleOverrideGroupSchedule,
      handleTogglePlaylistPause,
      handleTogglePlaylistShuffle,
      startSlideExport,
      startPlaylistMove,
      startPlaylistDuplicate,
    }
  },
  methods: { formatDate }
})
</script>

<style lang="less">
@import '../styles/variables';

#playlist-page-content {
  .full-height-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  background: #fff;
  height: 100%;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .playlist-empty {
    color: @text-color-secondary;
  }

  .playlist-wrapper {
    display: flex;
    flex-direction: column;
    .ant-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      .ant-collapse-item {
        display: flex;
        flex-direction: column;
        .ant-collapse-header {
          position: sticky;
          z-index: 2;
          top: 0;
          background-color: #fff;
        }
        .ant-collapse-content {
          flex: 1;
          flex-direction: column;
          display: flex;
          .ant-collapse-content-box {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0;
          }
        }
      }
      .ant-list {
        border: solid 2px transparent;
        flex: 1;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;

            .dragArea {
              height: 100%;
            }
          }
        }
      }

      .ant-list {
        min-height: 44px;
      }
    }

    &.dragging {
      .ant-collapse {
        .ant-list {
          border: dashed 2px var(--ant-primary-color);
        }
      }
    }
  }
}

.playlist-sider {
  header {
    padding: 20px 20px 12px 20px;

    .ant-radio-group {
      width: 100%;
      display: flex;

      .ant-radio-button-wrapper {
        flex: 1;
        text-align: center;
      }
    }
  }
  .ant-spin-nested-loading {
    overflow: hidden;
  }
  border-left: solid 1px #f0f0f0;
}
.widget-list-item {
  height: 32px;
  display: flex;
  cursor: pointer;
  .widget-tooltip {
    display: flex;
  }

  &.disabled {
    pointer-events: none;
    opacity: 1;
    cursor: not-allowed;
    background-color: @bg-light-grey;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &:first-child {
    border-top: solid 1px #f0f0f0;
  }

  .widget-item-icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .add-icon {
      display: none;
    }
  }

  .widget-item-title {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px 0 0;

    .anticon {
      display: inline-block;
      width: 24px;
      text-align: left;
    }
  }

  &:hover {
    .widget-item-icon {
      .move-icon {
        display: none;
      }

      .add-icon {
        display: flex;
      }

      &:hover {
        .move-icon {
          display: flex;
          cursor: move;
        }

        .add-icon {
          display: none;
        }
      }
    }
  }
}


</style>
