<template>
  <div id="change-logs">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <a-typography-title :level="5">
        {{ $t('components.changeLogs.title') }}
      </a-typography-title>
      <a-select
        v-model:value="logsFilter"
        style="width: 180px;"
      >
        <a-select-option value="all">
          {{ $t('components.changeLogs.allEvents') }}
        </a-select-option>
        <a-select-option
          v-for="member in members"
          :key="member.id"
          :value="member.id"
        >
          {{ member.name }}
        </a-select-option>
      </a-select>
    </div>
    <a-divider style="margin: 16px 0;" />
    <div class="logs-wrapper">
      <a-list
        id="infinite-list"
        item-layout="horizontal"
        :data-source="activityLogs"
        :loading="activityLogsLoading"
        @scroll="handleScroll"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                {{ item.userName }}
              </template>
              <template #description>
                <span v-html="item.getMessage(t)" />
              </template>
              <template #avatar>
                <a-avatar
                  v-if="item.avatar"
                  :size="48"
                  :src="item.avatar"
                />
                <a-avatar
                  v-else
                  :size="48"
                >
                  <template #icon>
                    <KitcastNotification />
                  </template>
                </a-avatar>
              </template>
            </a-list-item-meta>
            <div class="log-date">
              <a-typography-text>
                {{ item.date }}
              </a-typography-text>
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch, watchEffect } from 'vue'
import KitcastNotification from '@/components/icons/KitcastNotification'
import moment from 'moment'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

class ActivityLog {
  #id
  #author
  #createdAt
  #type
  #data
  constructor (logItem) {
    this.#id = logItem.id
    this.#author = logItem.author
    this.#type = logItem.type
    this.type = logItem.type
    this.#data = logItem.data || null
    this.#createdAt = logItem.createdAt
    this.avatar = this.#getAvatar()
    this.userName = this.#getUserName()
    this.date = this.#getDate()
  }

  #getDate = () => {
    return moment(this.#createdAt).format('MMM D, HH:mm')
  }

  #getAvatar = () => {
    return this.#author?.avatarMedia?.generatedMedia.find(m => m.tag === 'avatar64x64')?.url
  }

  #getUserName = () => {
    if (!this.#author) {
      return 'Workspace Admin'
    }
    return `${this.#author?.firstName} ${this.#author?.lastName && ' ' + this.#author.lastName}`
  }

  getMessage = (t) => {
    switch (this.#type) {
      case 'PLAYLIST_SLIDE_CREATED':
        return t('components.changeLogs.events.playlistSlideCreated', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_UPDATED':
        return t('components.changeLogs.events.playlistSlideUpdated', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_DELETED':
        return t('components.changeLogs.events.playlistSlideDeleted', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_DUPLICATED':
        return t('components.changeLogs.events.playlistSlideDuplicated', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_IMPORTED':
        return t('components.changeLogs.events.playlistSlideImported', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_CHANGED_ORDER':
        return t('components.changeLogs.events.playlistSlideChangedOrder', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_CHANGED_DURATION':
        return t('components.changeLogs.events.playlistSlideChangedDuration', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_MUTED':
        return t('components.changeLogs.events.playlistSlideMuted', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_SLIDE_UNMUTED':
        return t('components.changeLogs.events.playlistSlideUnmuted', {slideName: this.#data?.slideName, playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_ZONE_SETTINGS_UPDATED':
        return t('components.changeLogs.events.playlistZoneSettingsUpdated', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_VERSION_PUBLISHED':
        return t('components.changeLogs.events.playlistVersionPublished', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_CREATED':
        return t('components.changeLogs.events.playlistCreated', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_DELETED':
        return t('components.changeLogs.events.playlistDeleted', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_MOVED':
        return t('components.changeLogs.events.playlistDeleted', {playlistName: this.#data?.playlistName, fromGroupName: this.#data?.fromGroupName, toGroupName: this.#data?.toGroupName})
      case 'PLAYLIST_COPIED':
        return t('components.changeLogs.events.playlistCopied', {playlistName: this.#data?.playlistName, fromGroupName: this.#data?.fromGroupName, toGroupName: this.#data?.toGroupName})
      case 'PLAYLIST_TRANSITION_TYPE_CHANGED':
        return t('components.changeLogs.events.playlistTransitionTypeChanged', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'PLAYLIST_MUSIC_TYPE_CHANGED':
        return t('components.changeLogs.events.playlistMusicTypeChanged', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'DEVICE_LINKED':
        return t('components.changeLogs.events.deviceLinked', {deviceName: this.#data?.deviceName, groupName: this.#data?.groupName})
      case 'DEVICE_UNLINKED':
        return t('components.changeLogs.events.deviceUnlinked', {deviceName: this.#data?.deviceName, groupName: this.#data?.groupName})
      case 'DEVICE_RENAMED':
        return t('components.changeLogs.events.deviceRenamed', {deviceName: this.#data?.deviceName, groupName: this.#data?.groupName})
      case 'DEVICE_ORIENTATION_CHANGED':
        return t('components.changeLogs.events.deviceOrientationChanged', {deviceName: this.#data?.deviceName, groupName: this.#data?.groupName})
      case 'DEVICE_CONTENT_SYNC_SETTINGS_UPDATED':
        return t('components.changeLogs.deviceContentSyncSettingsUpdated', {deviceName: this.#data?.deviceName, groupName: this.#data?.groupName})
      case 'GROUP_CREATED':
        return t('components.changeLogs.events.groupCreated', {groupName: this.#data?.groupName})
      case 'GROUP_MOVED':
        return t('components.changeLogs.events.groupMoved', {groupName: this.#data?.groupName})
      case 'GROUP_DELETED':
        return t('components.changeLogs.events.groupDeleted', {groupName: this.#data?.groupName})
      case 'GROUP_RENAMED':
        return t('components.changeLogs.events.groupRenamed', {groupName: this.#data?.groupName})
      case 'SCHEDULE_ADDED':
        return t('components.changeLogs.events.scheduleAdded', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'SCHEDULE_REMOVED':
        return t('components.changeLogs.events.scheduleRemoved', {playlistName: this.#data?.playlistName, groupName: this.#data?.groupName})
      case 'MEDIA_CREATED':
        return t('components.changeLogs.events.mediaCreated', {mediaName: this.#data?.mediaName})
      case 'MEDIA_UPLOADED':
        return t('components.changeLogs.events.mediaUploaded', {mediaName: this.#data?.mediaName})
      case 'MEDIA_DELETED':
        return t('components.changeLogs.events.mediaDeleted', {mediaName: this.#data?.mediaName})
      case 'SOCIAL_ACCOUNT_LINKED':
        return t('components.changeLogs.events.socialAccountLinked', {socialAccountName: this.#data?.linkedSocialAccountName})
      case 'SOCIAL_ACCOUNT_UNLINKED':
        return t('components.changeLogs.events.socialAccountUnlinked', {socialAccountName: this.#data?.unlinkedSocialAccountName})
      case 'WORKSPACE_USER_ROLE_INVITED':
        return t('components.changeLogs.events.workspaceUserRoleInvited', {email: this.#data?.email, role: this.#data?.role})
      case 'WORKSPACE_USER_ROLE_CHANGED':
        return t('components.changeLogs.events.workspaceUserRoleChanged', {email: this.#data?.email, role: this.#data?.role})
      case 'WORKSPACE_USER_ROLE_REVOKED_ACCESS':
        return t('components.changeLogs.events.workspaceUserRoleRevokedAccess', {userName: this.#data?.userName, role: this.#data?.role})
      case 'WORKSPACE_USER_ROLE_RESTRICTIONS_UPDATED':
        return t('components.changeLogs.events.workspaceUserRoleRestrictionsUpdated', {userName: this.#data?.userName})
      case 'WORKSPACE_USER_ROLE_RESENT_INVITE':
        return t('components.changeLogs.events.workspaceUserRoleResentInvite', {email: this.#data?.email})
      case 'WORKSPACE_PAYMENT_SETTINGS_UPDATED':
        return t('components.changeLogs.events.workspacePaymentSettingsUpdated')
      case 'WORKSPACE_WATERMARK_SETTINGS_UPDATED':
        return t('components.changeLogs.events.workspaceWatermarkSettingsUpdated')
      case 'WORKSPACE_EMERGENCY_ALERT_SETTINGS_UPDATED':
        return t('components.changeLogs.events.workspaceEmergencyAlertSettingsUpdated')
      case 'WORKSPACE_CUSTOM_BRANDING_SETTINGS_UPDATED':
        return t('components.changeLogs.events.workspaceCustomBrandingSettingsUpdated')
      case 'USER_EMAIL_CHANGED':
        return t('components.changeLogs.events.userEmailChanged')
      case 'USER_AVATAR_CHANGED':
        return t('components.changeLogs.events.userAvatarChanged')
      case 'USER_PASSWORD_CHANGED':
        return t('components.changeLogs.events.userPasswordChanged')
      case 'USER_NAME_CHANGED':
        return t('components.changeLogs.events.userNameChanged')
      case 'USER_NOTIFICATION_SETTINGS_UPDATED':
        return t('components.changeLogs.events.userNotificationSettingsUpdated')
      default:
        return t('components.changeLogs.events.defaultEvent')
    }
  }
}

const LOGS_PER_PAGE = 10
const FILTER_ALL = 'all'

export default defineComponent({
  name: 'ChangeLogs',
  components: {
    KitcastNotification
  },
  setup () {
    const logsFilter = ref(FILTER_ALL)
    const store = useStore()
    const { t } = useI18n()
    const activityLogs = computed(()=> store.getters['workspace/activityLogs/data']?.map((log)=>new ActivityLog(log)))
    const pagination = computed(()=> store.getters['workspace/activityLogs/pagination'])
    const activityLogsLoading = computed(()=> store.getters['workspace/activityLogs/loading'])
    const members = computed(() => store.getters['workspace/members/members'].map(member => {
      return {
        id: member.userId,
        name: `${member.user?.firstName} ${member.user?.lastName}`
      }
    }))
    const logsTotal = computed(()=> pagination.value?.total)
    const paginationState = reactive({
      limit: LOGS_PER_PAGE,
      offset: 0
    })

    const filtersState = reactive({
      authorIdentityIds: null
    })

    const isEmpty = computed(()=>!activityLogs.value?.length)

    const hasMore = computed(()=>{
      return logsTotal.value > paginationState.offset + LOGS_PER_PAGE
    })

    const loadMore = () => {
      if (!hasMore.value) return
      paginationState.offset+=LOGS_PER_PAGE
      fetchActivityLogs()
    }

    const fetchActivityLogs = () => {
      store.dispatch('workspace/activityLogs/getActivityLogs', {pagination: paginationState, filters: filtersState })
    }

    watchEffect(()=>{
      fetchActivityLogs()
    })

    onMounted(() => {
      window.addEventListener("scroll", handleScroll, {passive: true})
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })


    const handleScroll = (e) => {
      const { clientHeight, scrollTop, scrollHeight } = e.target
      if (hasMore.value && !isEmpty.value && !activityLogsLoading.value && scrollTop + clientHeight >= scrollHeight - 10) {
        loadMore()
      }
    }

    watch(()=> logsFilter.value, (value)=> {
      filtersState.authorIdentityIds = logsFilter.value === FILTER_ALL ? [] : [value]
      paginationState.offset = 0
      const listElement = document.getElementById('infinite-list')
      listElement.scrollTo({
        top: 0
      })
      fetchActivityLogs()
    })

    return {
      logsFilter,
      activityLogs,
      activityLogsLoading,
      pagination,
      hasMore,
      members,
      t,
      handleScroll,
      loadMore
    }
  }
})
</script>

<style lang="less">
#change-logs {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  .logs-wrapper {
    flex-direction: column;
    display: flex;
    overflow: hidden;
    flex: 1;
    .ant-list {
      overflow-x: auto;
      .ant-avatar {
        border:0;
      }
      .log-date {
        padding-left: 80px;
      }
    }
  }
}

</style>
